var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Themba"},[_c('link',{attrs:{"href":"https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css","rel":"stylesheet"}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"LandingContent"},[_vm._m(7),_c('div',{staticClass:"LandingInput"},[_c('vs-button',{attrs:{"active":_vm.active == 0,"block":"","color":"#000000","size":"xl","href":"https://github.com/MkhuseliNgetu/Themba"},on:{"click":function($event){_vm.active = 0}}},[[_c('i',{staticClass:"bx bx-heart-circle",attrs:{"color":"#FFFFFFF"}}),_vm._v("   Build something new from what's broken ")]],2)],1)]),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageOne"},[_c('img',{attrs:{"id":"ThembaImageOne","src":require("../assets/Images/Themba/HeaderWomanOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageTwo"},[_c('img',{attrs:{"id":"ThembaImageTwo","src":require("../assets/Images/Themba/MiddleSectionHandOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageThree"},[_c('img',{attrs:{"id":"ThembaImageThree","src":require("../assets/Images/Themba/LeftCoupleOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageFour"},[_c('img',{attrs:{"id":"ThembaImageFour","src":require("../assets/Images/Themba/StrapOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageFive"},[_c('img',{attrs:{"id":"ThembaImageFive","src":require("../assets/Images/Themba/StrapTwo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageSix"},[_c('img',{attrs:{"id":"ThembaImageSix","src":require("../assets/Images/Themba/StrapThree.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThembaLandingImageSeven"},[_c('img',{attrs:{"id":"ThembaImageSeven","src":require("../assets/Images/Themba/StrapFour.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LandingTitle"},[_c('h1',[_vm._v("Themba")])])
}]

export { render, staticRenderFns }